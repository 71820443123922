
.dropdown {
    background: #26323C;
    border-radius: 4px;
    color: #ffffff;
    position: relative;
    min-width: 200px;
    width: 100%;
}

.dropdown-disabled {
    background: #1B2228;
    border-radius: 4px;
    color:  rgba(255, 255, 255, 0.4);
    position: relative;
    min-width: 200px;
    width: 100%;
    border: 1px solid #26323C;
}

.dropdown-disabled > .dropdown-header{
    padding: 11px 17px;
}

.dropdown-header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 18px ;

}

.dropdown-body {
    padding: 5px;
    display: none;
    min-width: 198px;
    width: 100%;
}

.dropdown-body.open {
    display: block;
    position: absolute;
    top: 98%;
    left: -3px;
    min-width: 198px;
    width: 100%;
}

.dropdown-item {
    padding: 12px 18px ;
    display: flex;
    background: #26323C;
    min-width: 198px;
    width: 100%;
}

.dropdown-item:hover {
    cursor: pointer;
}


.icon {
    font-size: 13px;
    color: #91A5BE;
    transform: rotate(0);
    margin-left: 20px;
}

.icon.open {
    /*transform: rotate(90deg);*/
}