.checkboxRow {
    display: flex;
    position: relative;
    padding-left: 20px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 13px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: #1b2228;
    margin-left: 16px;
    margin-right: 16px;
}

/* Hide the browser's default checkbox */
.checkboxRow input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background: #26323C;
}

.checkboxRow input:checked ~ .checkmark {
    background-color:  #26323C;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkboxRow input:checked ~ .checkmark:after {
    display: block;
}

.checkboxRow .checkmark:after {
    left: 5.5px;
    top: 2px;
    width: 3.5px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color:  #26323C;
}