.main {
    padding: 20px;
    font-size: 13px;
    font-weight: 600;
}

.container {
    position: relative;
    height: 8px;
    margin-bottom: 32px;
}

.range {
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: -8px;
    width: calc(100% + 16px);
    padding: 0;
    margin: 0;
    height: 10px;
    cursor: pointer;
    z-index: 10;
    -webkit-appearance: none;
}

.range::-webkit-slider-thumb {
    width: 8px;
    height: 8px;
}

.range::-moz-range-thumb {
    width: 8px;
    height: 8px;
}
.range::-ms-thumb {
    width: 8px;
    height: 8px;
}

.dot {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: #26323c;
    box-shadow: 0 0 0 3px #1b2228;
    cursor: pointer;
    transition: background-color 0.4s ease;
    z-index: 9;
}

.super__dot {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: #26323c;
    box-shadow: 0 0 0 4px #1b2228;
    cursor: pointer;
    transition: background-color 0.4s ease;
    z-index: 9;
}

.super__dot > .percent{
    top: 18px
}

.percent {
    position: absolute;
    top: 16px;
    left: 50%;
    font-size: 13px;
    transform: translate(-50%, 0);
    color: rgba(255, 255, 255, 0.4);
    transition: color 0.4s ease;
}

.super__minidot{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 3px !important;
    height: 3px !important;
    box-shadow: 0 0 0 2.7px;
    border-radius: 50%;
    background: white;
    z-index: 2000;
}

.active {
    background-color: #fff;
}

.active > .percent {
    color: #fff;
}

.one {
    left: 0;
}

.two {
    left: 25%;
}

.three {
    left: 50%;
}

.four {
    left: 75%;
}

.five {
    left: 100%;
}